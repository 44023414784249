// Import all plugins
// import 'jquery-sticky';
import * as bootstrap from 'bootstrap';
import "./import-jquery";
import "./import-bootstrap";
import typeWatch from 'jquery.typewatch';
import mixitup from 'mixitup';
import mixitupPagination from './mixitup-pagination';
mixitup.use(mixitupPagination);

import zxcvbn from 'zxcvbn/dist/zxcvbn';
import selectpicker from 'bootstrap-select';

// import 'jquery-focuspoint';
import {getBreakPointName, isWithinBreakpoint} from "./checkBootstrapBreakpoint";
import Cookies from "js-cookie";
import deparam from "jquery-deparam";
import fancybox from '@fancyapps/fancybox';

// import ScrollReveal from "scrollreveal";
import BootstrapDialog from 'bootstrap4-dialog';

import _ from "underscore";

//import "./jq-mobile-menu";
import "./jq-timed-message";
//import "./jq-sliders";
// import "./jq-module-tempsearch-list";


import ds81_initAutovalidatorForm from './jq-autovalidator-form_import.js';
// import {dsContactFormSizeTextarea, dsInitApplicationFormValidation} from "./jq-application-form";
import {formValidation, plugins} from "../formvalidation_1_8_1/es6/";
import Bootstrap from "../formvalidation_1_8_1/es6/plugins/Bootstrap";
import * as Ladda from "ladda";
import moment from 'moment';
import 'moment/locale/de';
moment.locale('de');

import "../../../node_modules/daterangepicker/daterangepicker";

import "./jq-contactform";


/**
 * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
 *
 * @private
 * @author Todd Motto
 * @link https://github.com/toddmotto/foreach
 * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
 * @callback requestCallback      callback   - Callback function for each iteration.
 * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
 * @returns {}
 */
var lm_forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};


export default (window._ = _, window.loadJsCssFile = loadJsCssFile, window.ds81_init_hold_to_confirm_buttons = ds81_init_hold_to_confirm_buttons, window.ds81_repeatEvery = ds81_repeatEvery, window.moment = moment, window.ds81_mix_init = ds81_mix_init);


function ds81_repeatEvery(func, interval) {
    // Check current time and calculate the delay until next interval
    var now = new Date(),
        delay = interval - now % interval;

    function start() {
        // Execute function now...
        func();
        // ... and every interval
        setInterval(func, interval);
    }

    // Delay execution until it's an even interval
    setTimeout(start, delay);
}

function ds81_reload_on_timedout_shift(){

    if(ds81_tempsearch_next_timeout_reload >= 0)
    {
        if(Math.floor(Date.now() / 1000) >= ds81_tempsearch_next_timeout_reload)
        {
            ds81_tempsearchlist_reload();
        }
    }

}
ds81_repeatEvery(ds81_reload_on_timedout_shift, 5000);

$(function() {

    //ds81_upgrade_links_to_fancybox();

    // Autovalidator Form init:
    $('form.autovalidator-form').each(function () {
        ds81_initAutovalidatorForm($(this));
    });


    // Set switch_employee handler:
    $('select[name="switch_employee"]').each(function () {
        $(this).selectpicker({
            styleBase: 'form-control',
            style: ''
        }).on('changed.bs.select', function () {
            console.log('Next employee', $(this).val());
            console.log('$(this).closest(\'.select-employee-root\')', $(this).closest('.select-employee-root').data('employee-switch-endpoint'));
            var employeeSwitchEndpoint = $(this).closest('.select-employee-root').data('employee-switch-endpoint') + $(this).val();
            console.log('enpoint', employeeSwitchEndpoint);

            $.ajax(
                employeeSwitchEndpoint, // Service-URL
                {
                    type: 'post',
                    dataType: 'json',
                    success: function (data, textStatus, jqXHR) { // AJAX was ok

                        if(typeof data !== "undefined")
                        {
                            if(typeof data.result !== "undefined")
                            {
                                if(data.result === true)
                                {
                                    location.reload();
                                }
                            }
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }

                }
            );
        });
    });
    $('.switch-employee-link').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $.ajax(
            $(this).attr('href'), // Service-URL
            {
                type: 'post',
                dataType: 'json',
                success: function (data, textStatus, jqXHR) { // AJAX was ok

                    if(typeof data !== "undefined")
                    {
                        if(typeof data.result !== "undefined")
                        {
                            if(data.result === true)
                            {
                                location.reload();
                            }
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {

                }

            }
        );
    })

    // Hash detect:
    window.addEventListener('hashchange', ds81_handleHash);
    $(window).on('load', ds81_handleHash);
    ds81_handleHash();
    $('.btn.hashChange').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $item = $($(this).attr('href')).first();

        var scrollDist = $('main').scrollTop() + Math.floor($item.offset().top);

        var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .5));
        $('main').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
        $('body').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
        $('html').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
    });

    // Load postInit scripts:
    if(typeof loadJsCssFileList !== "undefined")
    {
        for(var i in loadJsCssFileList)
        {
            var task = loadJsCssFileList[i];
            loadJsCssFile(task[0], task[1], task[2]);
        }
    }

    // Min height to have footer at bottom:
    $(window).on('resize load', ds81_set_min_fill_height);
    // Safari bugfix: Reposition image on load:
    $("img").on('load', function () {
        $(this).off('load');
        ds81_set_min_fill_height();
    }).each(function () {
        if (this.complete) {
            $(this).off('load');
            ds81_set_min_fill_height();
        }
    });
    ds81_set_min_fill_height();

    // Handle changes in consent selection:
    $(window).on('dscookiebar_consentchange', dscookiebar_handle_consent_change);
    dscookiebar_handle_consent_change();

    // Check and handle cookie bar show/hide:
    if($('#dscookiebar_consent').length > 0)
    {
        // Consent version id:
        var cookieVersion = $('#dscookiebar_consent').first().data('version');

        // Cookie path:
        var cookiePath = typeof path_root !== "undefined" ? path_root : '';

        // Read cookie:
        if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) === "undefined" || Cookies.get('dscookieconsent_version', {path: cookiePath}) != cookieVersion)
        {
            $('#dscookiebar_consent').each(function () {

                // Prevent form submit:
                $(this).find('form').submit(function(e){
                    e.preventDefault();
                });

                // Link select checkboxes:
                $(this).find('input[type="checkbox"]').each(function (){
                    if(typeof $(this).attr('checked') !== "undefined"){
                        $(this).prop('checked', true);
                    }
                });
                $(this).find('input[type="checkbox"]').on('change', function () {
                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_onpage_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));
                });

                $(this).removeClass('d-none');
                $(this).find('.content-select-consent').addClass('d-none');
                $(this).find('.content-default').removeClass('d-none');

                $(this).find('.btn-accept-all').on('click.dscookiebaracceptall', dscookiebar_accept_all_handler);
                $(this).find('.btn-make-choice').on('click.dscookiebarmakechoice', dscookiebar_show_select_consent_content);
                $(this).find('.btn-cancel-consent-select').on('click.dscookiebarcancel', dscookiebar_show_default_content);
                $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

                $('footer').css('margin-bottom', $(this).outerHeight(true) + 'px');

            });

        }

    }

    // Link onpage component:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Prevent form submit:
            $(this).submit(function(e){
                e.preventDefault();
            });

            // Link select checkboxes:
            $(this).find('input[type="checkbox"]').each(function (){
                if(typeof $(this).attr('checked') !== "undefined"){
                    $(this).prop('checked', true);
                }
            });

            // Process checkboxes:
            $(this).find('input[type="checkbox"]').each(function () {

                // Set initial state:
                if(dscookiebar_has_consent($(this).attr('name')))
                {
                    $(this).prop('checked', true);
                }


                // Link checkbox to transfer state to cookie bar:
                $(this).on('change', function () {

                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

                    // Check status:
                    dscookiebar_onpage_check_status();

                });

            });

            // Link save button:
            $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

        });

        // Listen to change events to update onpage status:
        $(window).on('dscookiebar_consentchange', function () {

            // Check status:
            dscookiebar_onpage_check_status();

        });

        // Initial status check:
        dscookiebar_onpage_check_status();

    }

    // Initialize square gallery:
    // if($('.square-gallery').length > 0)
    // {
    //     $(window).on('resize.ds81_square_gallery_sizer', ds81_square_gallery_sizer);
    //     ds81_square_gallery_sizer();
    // }

    // Scrollreveal:
    // $('.srev-p p').addClass('srev');
    // ScrollReveal().reveal('.srev', { delay: 100, distance: '50px', reset: false, interval: 100 });



    // Mobile nav open close:
    $('.oc-nav-trigger').on('click.nav-main-toggle',function () {
        $(this).find('.hamburger').toggleClass('is-active');
        if($(this).find('.hamburger').hasClass('is-active'))
        {
            $('html').addClass('nav-on');

            let itemCount = $('.nav-items .items-col').length;
            $('.nav-items .items-col').each(function (){
                $(this).animate({
                    opacity: 0
                }, 0, function (){
                    $(this).delay((itemCount - $(this).index()) * 50).animate({
                        opacity: 1,
                    }, 200);
                });
            });


        }
        else
        {
            $('html').removeClass('nav-on');

            $('.nav-items .items-col').each(function (){
                $(this).delay($(this).index() * 40).animate({
                    opacity: 0,
                }, 60);
            });
        }
    });
    $('.nav-backdrop-layer').on('click.nav-main-close-via-layer', function () {
        $('.oc-nav-trigger').first().trigger('click');
    });

    // $('.oc-nav-trigger').first().trigger('click');

    var subLevelLists = document.querySelectorAll(".mona-nav-list-main > li > ul");
    if (subLevelLists.length > 0) {
        lm_forEach(subLevelLists, function(subLevelListItem) {
            $(subLevelListItem).slideUp(0);
        });
    }

    // -- Mobile navigation plus button slider -------------------
    var subLevelTriggers = document.querySelectorAll(".mona-nav-list-main .sublevel-trigger");
    if (subLevelTriggers.length > 0) {
        lm_forEach(subLevelTriggers, function(subLevelTrigger) {
            if($(subLevelTrigger).hasClass('open'))
            {
                $(subLevelTrigger).closest('li').find('> ul').slideDown(199);
            }
            subLevelTrigger.addEventListener("click", function(e) {

                subLevelTrigger.classList.toggle("open");

                if($(subLevelTrigger).hasClass('open'))
                {
                    $(e.currentTarget).closest('ul').find('.sublevel-trigger.open').each(function (){
                        if($(this).closest('li').index() != $(e.currentTarget).closest('li').index())
                        {
                            $(this).removeClass('open');
                            $(this).closest('li').find('> ul').slideUp(199);
                        }
                    })

                    $('.mona-nav-list-main').animate({
                        scrollTop: $(e.currentTarget).closest('li').position().top - parseInt($('.mona-nav-list-main').parent().css('paddingTop')) + $('.mona-nav-list-main').scrollTop()
                    }, 100);
                    $(subLevelTrigger).closest('li').find('> ul').slideDown(199);
                }
                else
                {
                    $(subLevelTrigger).closest('li').find('> ul').slideUp(199);
                }

            }, false);
        });
    }
    // -- END mobile navigation plus button slider -------------------

    // Activate open element:
    $('.mona-nav-list-main > li.active').find('> .sublevel-trigger').trigger('click');




    // Check for and initialize push connection:
    if(typeof pushconnection_employee !== "undefined")
    {
        // Parse string data to JSON object:
        var pushConnectionData = JSON.parse(pushconnection_employee);

        localStorage.debug = '-';

        loadJsCssFile(pushConnectionData.host + '/socket.io/socket.io.js', 'js', function () {

            // Initialize socket:
            var socket = io(pushConnectionData.host + '/' + pushConnectionData.namespace);

            // Handle "event" type request: Trigger window event.
            socket.on('event', function(msg) {
                if(typeof msg === "object")
                {
                    $(window).trigger(msg.event, msg.payload);
                }
                else
                {
                    $(window).trigger(msg);
                }
            });

        });

    }

});


function ds81_init_hold_to_confirm_buttons($target) {

    if (typeof $target === "undefined")
    {
        $target = $('body');
    }


    $target.find('.btn-hold-to-confirm').each(function () {
       if(!$(this).hasClass('btn-hold-init'))
       {
           $(this).addClass('btn-hold-init');
            $(this).on('mousedown touchstart', function () {
                $(this).addClass('animating');
                var durationValue = parseInt($(this).data('hold-timeout'));
                $(this).find('.progress-bar').attr('style', 'transition-duration: ' + durationValue + 'ms!important;');
                $(this).find('.progress-bar-pseudo').animate({
                    width: '100%',
                },{
                    easing: 'linear',
                    progress: function (animation, progress, remainingMs) {
                        console.log('PROG', animation, durationValue, remainingMs, 100 - ((100/durationValue) * remainingMs));
                        // $(animation.elem).css('width', (remainingMs, 100 - ((100/durationValue) * remainingMs)) + '%');
                        $(animation.elem).parent().attr('aria-valuenow', (remainingMs, 100 - ((100/parseInt($(animation.elem).closest('.btn-hold-to-confirm').data('hold-timeout'))) * remainingMs)));
                    },
                    complete: function () {
                        console.log("FIRE!");
                        $(this).trigger('holdConfirmed');
                    },
                    duration: durationValue
                });
            }).on('mouseup mouseleave mouseout touchend touchcancel', function () {
                if($(this).hasClass('animating'))
                {
                    $(this).removeClass('animating');
                    $(this).find('.progress-bar').attr('style', null);
                    $(this).find('.progress-bar-pseudo').stop(true);
                    $(this).find('.progress-bar-pseudo').animate({
                        width: '0',
                    },{
                        easing: 'linear',
                        progress: function (animation, progress, remainingMs) {
                            $(animation.elem).parent().attr('aria-valuenow', (remainingMs, 100 - ((100/parseInt($(animation.elem).closest('.btn-hold-to-confirm').data('hold-timeout'))) * remainingMs)));
                        },
                        complete: function () {
                            $(this).find('.progress-bar-pseudo').stop(true);
                            console.log("ALL CLEAR");
                        },
                        duration: 50
                    });
                }
                console.log("OUT CANCEL");
            });
       }
    });


}

function ds81_upgrade_links_to_fancybox(){


    // Upgrade links to use fancybox:
    $('a[href^="/"]').each(function () {
        if(!$(this).hasClass('ds-fancybox-init'))
        {
            $(this).addClass('ds-fancybox-init')
            $(this).attr('data-fancybox', 'ajaxbox' + $(this).index());
            $(this).attr('data-type', 'ajax');
            $(this).attr('data-src', $(this).attr('href'));
            $(this).attr('data-filter', '#mainContent');
        }
    });

    $().fancybox({
        selector  : '[data-fancybox]',
        beforeShow : function() {

            $.fancybox.getInstance().hideControls();
        },
        afterShow : function() {

            $.fancybox.getInstance().hideControls();

            ds81_upgrade_links_to_fancybox();

            // Link onpage component:
            if($('#dscookiebar_onpage_consent').length > 0)
            {
                $('#dscookiebar_onpage_consent').each(function () {

                    // Prevent form submit:
                    $(this).submit(function(e){
                        e.preventDefault();
                    });

                    // Link select checkboxes:
                    $(this).find('input[type="checkbox"]').each(function (){
                        if(typeof $(this).attr('checked') !== "undefined"){
                            $(this).prop('checked', true);
                        }
                    });

                    // Process checkboxes:
                    $(this).find('input[type="checkbox"]').each(function () {

                        // Set initial state:
                        if(dscookiebar_has_consent($(this).attr('name')))
                        {
                            $(this).prop('checked', true);
                        }


                        // Link checkbox to transfer state to cookie bar:
                        $(this).on('change', function () {

                            // Transfer status to possibly present cookie bar:
                            $('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

                            // Check status:
                            dscookiebar_onpage_check_status();

                        });

                    });

                    // Link save button:
                    $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

                });

                // Listen to change events to update onpage status:
                $(window).on('dscookiebar_consentchange', function () {

                    // Check status:
                    dscookiebar_onpage_check_status();

                });

                // Initial status check:
                dscookiebar_onpage_check_status();

            }


        }
    });

}
//
// function ds81_square_gallery_sizer() {
//     $('.square-gallery-item').each(function () {
//         $(this).find('.square-gallery-item-inner').css('height', '');
//         $(this).find('.square-gallery-item-inner').css('height', $(this).width() + 'px');
//
//         $(this).find('.square-gallery-item-inner').each(function () {
//
//             if($(this).hasClass('focuspoint'))
//             {
//                 $(this).data('focusPoint').adjustFocus();
//             }
//
//             if(!$(this).hasClass('focuspoint') && !$(this).hasClass('square-bg'))
//             {
//                 $(this).addClass('square-bg');
//                 var $img = $(this).find('img').first();
//                 var imgSrc = $img.attr('src');
//                 var $picture = $img.closest('picture');
//                 if($picture.length > 0)
//                 {
//                     if($('html').hasClass('webp'))
//                     {
//                         var $webp = $picture.find('[type="image/webp"]');
//                         if($webp.length > 0)
//                         {
//                             imgSrc = $webp.attr('srcset');
//                         }
//                     }
//                 }
//                 $(this).css('background-image', 'url(' + imgSrc + ')');
//                 $img.css('visibility', 'hidden');
//             }
//         });
//
//
//     });
// }
//
// function dsSetImageHeights()
// {
//     // Adjust images with height reference:
//     $('[data-adjust-image-height-ref]').each(function () {
//         var $root = $(this).parent();
//         if(typeof $(this).data('adjust-image-height-root') !== "undefined")
//         {
//             $root = $(this).closest($(this).data('adjust-image-height-root'));
//         }
//         var $ref = $root.find('[data-height-for]').first();
//         if($ref.length > 0)
//         {
//             $(this).css('height', $ref.innerHeight() + 'px');
//         }
//
//     });
//
//     // Set image heights accoring to breakpoint (supersedes height reference);
//     $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'visible');
//     var bb = getBreakPointName();
//     switch(bb)
//     {
//         case('xs'):
//             $('[data-i-height-xs]').each(function () {
//                 $(this).css('height', $(this).data('i-height-xs') + 'px');
//             });
//             break;
//         case('sm'):
//             $('[data-i-height-sm]').each(function () {
//                 $(this).css('height', $(this).data('i-height-sm') + 'px');
//             });
//             break;
//         case('md'):
//             $('[data-i-height-md]').each(function () {
//                 $(this).css('height', $(this).data('i-height-md') + 'px');
//             });
//             break;
//         case('lg'):
//             $('[data-i-height-lg]').each(function () {
//                 $(this).css('height', $(this).data('i-height-lg') + 'px');
//             });
//             break;
//         default:
//             $('[data-i-height-xl]').each(function () {
//                 $(this).css('height', $(this).data('i-height-xl') + 'px');
//             });
//             break;
//     }
//     // $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'hidden');
//
//     // Activate focuspoint on images:
//     $('.focuspoint').focusPoint();
//
//     // Trigger re-init on image load:
//     $('img').each(function () {
//         if(!$(this).hasClass('load-init') && !$(this).hasClass('loaded'))
//         {
//             $(this).addClass('load-init');
//             $(this).one("load", function () {
//                 if(this.complete) {
//                     $(this).addClass('loaded').removeClass('load-init');
//                     dsSetImageHeights();
//                 }
//             });
//         }
//     });
//
// }


function dscookiebar_handle_consent_change()
{
    // Update onpage instances:
    var $targets = $('body').find('.dscookiebar,.consent-item-list-onpage');
    $targets.find('input[type="checkbox"]').each(function () {

        // Set current state:
        if (dscookiebar_has_consent($(this).attr('name'))) {
            $(this).prop('checked', true);
        }
        else
        {
            $(this).prop('checked', false);
        }

    });


    // Google Analytics:
    if(dscookiebar_has_consent('ga'))
    {
        dscookiebar_load_and_fire_tracking_calls();
    }

    // Show/hide consent elements:
    $('[data-consent-required]').each(function (){

        // Get consent id:
        var myConsent = $(this).data('consent-required');

        $('.addconsent').on('click', function (e){
            e.preventDefault();
            dscookiebar_add_consent($(this).data('addconsent'));
        });

        if(dscookiebar_has_consent(myConsent))
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').removeClass('d-none');
            $(this).find('[data-ifconsent="no"]').addClass('d-none');

            // Parse template:
            var pluginTemplate = $(this).find('[data-ifconsent="yes"] .plugin-template').first();
            if(pluginTemplate.length > 0)
            {
                // console.log('IN');
                var tpl = _.template(pluginTemplate.html());
                var tplRendered = tpl({});
                $(this).find('[data-ifconsent="yes"]').html(tplRendered);
            }
        }
        else
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').addClass('d-none');
            $(this).find('[data-ifconsent="no"]').removeClass('d-none');
        }

    });

}

function dscookiebar_add_consent(addConsent, callback){

    var $bar = $('body .dscookiebar').first();

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');

    // Consent found?
    var newConsentFound = false;

    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        if(cookieIDs[i] == addConsent)
        {
            newConsentFound = true;
        }
        var setValue = 0;
        if(dscookiebar_has_consent(cookieIDs[i]) || cookieIDs[i] == addConsent)
        {
            setValue = 1;
        }
        cookieStringList.push(cookieIDs[i] + '=' + setValue);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

    // Callback?
    if(typeof callback == 'function')
    {
        // console.log('FN A', arguments);
        callback(arguments[2], arguments[3], arguments[4], arguments[5]);
    }

}


function dscookiebar_onpage_check_status() {

    // Check current status and enable save button if onpage settings do not match current consent:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Flag: All settings match current selection:
            var allMatch = true; // Assume true first.

            // Process checkboxes and check selection:
            $(this).find('input[type="checkbox"]').each(function () {

                // Check status:
                if(dscookiebar_has_consent($(this).attr('name')) != $(this).prop('checked'))
                {
                    // No match. Checkbox state does not equal current consent.
                    allMatch = false;
                }

            });

            // Set button status:
            $(this).find('.btn-save-consent-settings').prop('disabled', allMatch);
            if(allMatch)
            {
                $(this).find('.message-all-up-to-date').removeClass('d-none');
            }
            else
            {
                $(this).find('.message-all-up-to-date').addClass('d-none');
            }

        });
    }

}

function dscookiebar_has_consent(which) {
    var allConsents = dscookiebar_get_consents();
    return jQuery.inArray( which, allConsents) >= 0;
}

function dscookiebar_get_consents() {

    // Consent version id:
    var cookieVersion = $('#dscookiebar_consent').first().data('version');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Prepeare return array:
    var ret = [];

    // Read cookie:
    if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) !== "undefined" && Cookies.get('dscookieconsent_version', {path: cookiePath}) == cookieVersion)
    {
        // Get cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
        var consentData = Cookies.get('dscookieconsent', { path: cookiePath });

        // Data from cookie obtained?
        if(typeof consentData !== "undefined")
        {
            var consentArray = deparam(consentData);
            for(var i in consentArray)
            {
                if(parseInt(consentArray[i]) > 0)
                {
                    ret.push(i);
                }
            }
        }

    }

    // Return given consents:
    return ret;

}

function dscookiebar_hide_bar($bar) {

    $('html').removeClass('dscookiebar-show-cover');

    $bar.find('.inner').animate({
        // height: 0,
        opacity: 0,
    }, {
        duration: 300,
        complete: function () {
            $(this).closest('.dscookiebar').hide();
        }
        // ,
        // step: function (now, tween) {
        // $(this).parent().css('height', $(this).outerHeight() + 'px');
        // }
    });

}

function dscookiebar_accept_all_handler(e) {

    if(typeof e !== "undefined")
    {
        e.preventDefault();
    }

    // Get references:
    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        cookieStringList.push(cookieIDs[i] + '=1');
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_show_select_consent_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').removeClass('d-none');
    $bar.find('.content-default').addClass('d-none');
    $('html').addClass('dscookiebar-show-cover');

}

function dscookiebar_show_default_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').addClass('d-none');
    $bar.find('.content-default').removeClass('d-none');
    $('html').removeClass('dscookiebar-show-cover');
}

function dscookiebar_save_consent(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $form = $button.closest('form');

    // Find bar to obtain data:
    var $bar = $('#dscookiebar_consent').first();

    var checked = [];
    $form.find('input.consent-check:checked').each(function () {
        checked.push($(this).attr('name'));
    });

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var mandatoryIDs = $bar.data('mandatory-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        var myId = cookieIDs[i];
        var myState = 0;

        // Selected or mandatory?
        if(jQuery.inArray( myId, checked) >= 0 || jQuery.inArray( myId, mandatoryIDs) >= 0)
        {
            myState = 1;
        }

        cookieStringList.push(myId + '=' + myState);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');
}

// var dscookiebar_current_page_tracked = false;
// var dsGtag;
// function dscookiebar_load_and_fire_tracking_calls()
// {
//
//     // No tracking if backend user is logged in:
//     if(typeof dp_prevent_count !== "undefined") return false;
//
//     // dp_ga_uid set and has content?
//     if(typeof dp_ga_uid === 'undefined' || dp_ga_uid.length < 1) return false;
//
//     if(dscookiebar_current_page_tracked === false)
//     {
//         dscookiebar_current_page_tracked = true;
//         loadJsCssFile('https://www.googletagmanager.com/gtag/js?id=' + dp_ga_uid, 'js', function () {
//             window.dataLayer = window.dataLayer || [];
//             function gtag(){dataLayer.push(arguments);}
//             gtag('js', new Date());
//             gtag('config', dp_ga_uid, { 'anonymize_ip': true });
//             dsGtag = gtag;
//         });
//     }
// }



// File loader:
export var _us = _;
function loadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}


function ds81_handleHash(){

    setTimeout(function (){


        if(window.location.hash.indexOf('=') < 0 && $(window.location.hash).length > 0)
        {
            var $item = $(window.location.hash).first();
            var scrollDist = Math.floor($item.offset().top);
            var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .5));
            $('html, body').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
        }

    }, 120);
}

function ds81_set_min_fill_height(){
    $('.min-height-fill').css('height', '0px');
    if($('body').outerHeight() < winDim().height)
    {
        // var minFillHeight = winDim().height - $('footer').outerHeight(true) - $('header').outerHeight(true);
        var minFillHeight = winDim().height - $('main').outerHeight(true);
        $('.min-height-fill').css('height', minFillHeight + 'px');
    }
}

function winDim(){
    // compute window width:
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        winWidth = w.innerWidth || e.clientWidth || g.clientWidth,
        winHeight = w.innerHeight|| e.clientHeight|| g.clientHeight;

    return {width: winWidth, height: winHeight };
}


var mixController;

function ds81_mix_init($target, callback, extendConfig)
{

    // Defaults:
    var pagerListLength = $target.data('pager-listlength');
    if(typeof pagerListLength == 'undefined') pagerListLength = 10;
    var pagerMaxPagers = $target.data('pager-maxpagers');
    if(typeof pagerMaxPagers == 'undefined') pagerMaxPagers = 5;

    if($target.find('select[name="i_pagination_length"]').length > 0) {
        pagerListLength = $target.find('select[name="i_pagination_length"]').val();
    }

    // Pagination length:
    if($target.find('select[name="i_pagination_length"]').length > 0) {
        $lengthSelect = $target.find('select[name="i_pagination_length"]');

        if ($lengthSelect.length > 0)
        {
            $lengthSelect.val();

            $lengthSelect.on('changed.bs.select', function () {
                var thisMixController = $(this).closest('.mixitup-container').data('mix-instance');

                if(parseInt($(this).val()) > 0)
                {
                    thisMixController.paginate({
                        limit: $(this).val()
                    });
                }
                else
                {
                    thisMixController.paginate({
                        limit: 100000
                    });
                }
            });

        }
    }

    // Add listener to filter affecting select fields:
    $target.find('.filter-select').on('changed.bs.select', function (){

        var _selectPicker = $(this);

        if(typeof $(this).val() === 'object')
        {
            _.each($(this).val(), function (val) {
                if(val == 'resetfilter'){
                    _selectPicker.selectpicker('deselectAll');
                    _selectPicker.selectpicker('toggle');
                }
            });

        }
        if(typeof $(this).val() === 'string' && $(this).val().length > 0)
        {
            if($(this).val() == 'resetfilter')
            {
                _selectPicker.selectpicker('deselectAll');
                _selectPicker.selectpicker('toggle');

            }
        }

        mixFilter($(this).closest('.mixitup-container').data('mix-instance'));
    });

    var mixConfig = {
        controls:{
            scope: 'local'
        },
        selectors: {
            target: '.mix-element',
            control: '.mixitup-control'
        },
        classNames: {
            elementPager: 'control btn-primary btn-sm btn'
        },
        animation: {
            duration: 200,
            enable: true
        },
        pagination: {
            limit: pagerListLength,
            hidePageListIfSinglePage: true,
            maxPagers: pagerMaxPagers
        },
        callbacks: {
            onMixStart: function(state, futureState) {
                mixSetResultMessages(state, futureState);
            }
        }
    };


    if(typeof extendConfig !== "undefined"){
        mixConfig = $.extend(mixConfig, extendConfig);
    }

    // init MixItUp:
    mixController = mixitup($target, mixConfig);

    // push mix intance to data:
    $target.data('mix-instance', mixController);

    // Link customer elemets to detail pages:
    $target.find('.mix-element[data-href]').on('click', function(){
        location.href = $(this).data('href');
    }).css('cursor', 'pointer');

    // Add keyup listener to text search:
    $target.find('input[name="i_search_filter"]').typeWatch({
        callback: function (value) {
            var currentMixController = $(this).closest('.mixitup-container').data('mix-instance');
            mixFilter(currentMixController);
        },
        wait: 50,
        highlight: true,
        allowSubmit: false,
        captureLength: 1
    });

    // Initial sort:
    if(typeof $target.data('defaultsort') !== "undefined" && $target.data('defaultsort').length > 0)
    {
        mixController.sort($target.data('defaultsort'), false);
    }
    else
    {
        if($target.find('.mixitup-control.mixitup-default-sort').length > 0)
        {
            $target.find('.mixitup-control.mixitup-default-sort').trigger('click');
            mixController.sort($target.find('.mixitup-control.mixitup-default-sort').data('sort'), false);
        }
    }

    var stateAfterLoad = mixController.getState();
    mixSetResultMessages(stateAfterLoad, stateAfterLoad);

    if(typeof callback !== "undefined")
    {
        callback();
    }

}

function mixSetResultMessages(state, futureState)
{
    let $target = $(futureState.container);
    let $messageNoFilterMatchMessage = $target.find('.no-filter-match-message');
    let $messageNoItemsMessage = $target.find('.no-items-present-message');

    let $hideIfNoFilterMatchElements = $target.find('.hide-if-no-filter-match');
    let $hideIfNoItemsElements = $target.find('.hide-if-no-content');

    if(futureState.totalTargets > 0)
    {
        $messageNoItemsMessage.addClass('d-none');
        $hideIfNoItemsElements.removeClass('force-hide');

        if(futureState.totalMatching < 1)
        {
            $messageNoFilterMatchMessage.removeClass('d-none');
            $hideIfNoFilterMatchElements.addClass('force-hide');
        }
        else
        {
            $messageNoFilterMatchMessage.addClass('d-none');
            $hideIfNoFilterMatchElements.removeClass('force-hide');
        }
    }
    else
    {
        $hideIfNoItemsElements.addClass('force-hide');
        $messageNoItemsMessage.removeClass('d-none');
    }
}

function mixCheckStatus(mixController)
{
    var state = mixController.getState();
    let $target = $(state.container);
    let $messageNoFilterMatchMessage = $target.find('.no-filter-match-message');
    let $messageNoItemsMessage = $target.find('.no-items-present-message');

    let $hideIfNoFilterMatchElements = $target.find('.hide-if-no-filter-match');
    let $hideIfNoItemsElements = $target.find('.hide-if-no-content');

    if(state.targets.length < 1)
    {
        $hideIfNoItemsElements.addClass('force-hide');
        $messageNoItemsMessage.removeClass('d-none').removeClass('force-hide');

        return false;
    }

    return true;

}

function mixFilter(mixController, reset){

    // Set target:
    let $target = $(mixController.getState().container);

    console.log('$target', mixController.getState());

    // Get all elements:
    var matchedElements = $target.find('.mix-element[data-filtername]');

    if(typeof reset !== "undefined" && reset == true)
    {
        mixController.filter(matchedElements);
        return true;
    }

    // Find filter affecting selects:
    $target.find('.filter-select').each(function (){

        // Filter by multi select value list (multiple classes may match)
        if(typeof $(this).val() === 'object' && $(this).val().length > 0)
        {
            var classAttr = $(this).val().join(', ');
            matchedElements = matchedElements.filter(classAttr);

        }

        // filter by single class must match:
        if(typeof $(this).val() === 'string' && $(this).val().length > 0)
        {
            var testClass = $(this).val();
            matchedElements = matchedElements.filter(function () {
                return $(this).hasClass(testClass);
            });
        }

    });

    // Capture text search string:
    if($target.find('input[name="i_search_filter"]').length > 0)
    {
        var searchText = $target.find('input[name="i_search_filter"]').val();

        // Match elements against text search:
        if(searchText.length > 0)
        {
            _.each(searchText.split(' '), function (searchword) {
                // Create regex string and final regex
                var regexStr = '.*' + searchword + '.*';
                var regex = new RegExp(regexStr, 'i'); // i for case ignore
                matchedElements = matchedElements.filter(function () {
                    return regex.test($(this).data('filtername'));
                });
            });
        }
    }

    // Mix refresh:
    mixController.filter(matchedElements);

}


function ds81_tempsearchlist_render_dataset_tempsearch(tempsearch)
{
    // prepare tempsearchlist tempsearch:
    var tplHtml = $('.template_tempsearch_list_item').html();

    // Prepare template:
    var tpl = _.template(tplHtml);

    // Add all data:
    tempsearch.all_data_json = JSON.stringify(tempsearch);

    // Render template:
    var tplRendered = tpl(tempsearch);

    return tplRendered;

}

function ds81_tempsearchlist_get_query(){

    // define root for input elements:
    let $inputRoot = $('.tempsearch-block-root');


    // Prepare data:
    var queryData = {
        // text search value:
        t: $.trim($inputRoot.find('input[name="i_search_filter"]').first().val()),
        // desired tempsearch per page length and current page:
        pi: 10,//$inputRoot.find('select[name="i_pagination_length"]').first().val(),
        pp: $inputRoot.data('selected-page'),
    }

    return new URLSearchParams(queryData);

}

var ds81_tempsearchlist_reload_instance = false;
var ds81_tempsearch_next_timeout_reload = -1;
function ds81_tempsearchlist_reload(){


    if(ds81_tempsearchlist_reload_instance !== false)
    {
        ds81_tempsearchlist_reload_instance.abort();
        ds81_tempsearchlist_reload_instance = false;
    }

    // Show loading spinner:
    $('.shift-list-loading-icon').removeClass('d-none');

    $('.mixitup-container-tempsearch-list').each(function () {


        // Different endpoint url required?
        var endpointUrl = '';
        if($(this).data('endpoint'))
        {
            endpointUrl = $(this).data('endpoint')  + ds81_tempsearchlist_get_query();;
        }
        else
        {
            endpointUrl = app_base_path + 'tempsearch/get/list/json/' + ds81_tempsearchlist_get_query();
        }

        var _this = $(this);

        ds81_tempsearchlist_reload_instance = $.get(endpointUrl, function( data ) {

            // Hide loading spinner:
            $('.shift-list-loading-icon').addClass('d-none');

            // Get MixItUp:
            var mixController = _this.data('mix-instance');

            // Parse return data:
            var dataListPlain = [];
            for(var i in data.tempsearch)
            {
                // Capture shift start timestamp to reload after shift start:
                var shift_start_timestamp = parseInt(data.tempsearch[i].shift_start_timestamp);
                if(ds81_tempsearch_next_timeout_reload < 0 || shift_start_timestamp < ds81_tempsearch_next_timeout_reload)
                {
                    ds81_tempsearch_next_timeout_reload = shift_start_timestamp;
                }

                // Push to collection:
                dataListPlain.push(data.tempsearch[i]);
            }

            // console.log('dataListPlain', dataListPlain);
            // console.log('mixController', mixController);


            // Build pagination if required:
            if(typeof data.pagination !== "undefined")
            {
                if(data.pagination.pagesTotal > 0)
                {


                    // Render list:
                    mixController.dataset(dataListPlain).then(function (state) {

                        // Activate delete handlers:
                        setTimeout(function () {
                            ds81_init_shift_items();
                        }, 20);
                    });

                }
                else
                {
                    mixController.dataset([]);
                }

                if(data.pagination.pagesTotal > 1)
                {

                    let $target = $('.tempsearch-block-root');

                    // Show messages depending on result set:
                    let $messageNoFilterMatchMessage = $target.find('.no-filter-match-message');
                    let $messageNoItemsMessage = $target.find('.no-itemss-present-message');

                    $messageNoFilterMatchMessage.addClass('d-none');
                    $messageNoItemsMessage.addClass('d-none');

                    // Build pagination items list:
                    var listItemHtml = '';
                    for(var i = 1; i < data.pagination.pagesTotal + 1; i ++)
                    {
                        // Load template:
                        var tplHtml = $('.template_tempsearchlist_pagination_list_item').html();

                        // Prepare template:
                        var tpl = _.template(tplHtml);

                        // Render template:
                        listItemHtml += tpl({
                            activeClass: i == data.pagination.currentPage ? ' active' : '',
                            page: i
                        });
                    }

                    // Load template:
                    var tplHtml = $('.template_tempsearchlist_pagination_list_wrapper').html();

                    // Prepare template:
                    var tpl = _.template(tplHtml);

                    // Render template:
                    $target.find('.pagination-list').html(tpl({
                        list: listItemHtml
                    }));
                    $target.find('.pagination-list .page-item').on('click', function (e) {
                        e.preventDefault();
                        $('.tempsearch-block-root').data('selected-page', $(this).data('index'));
                        ds81_tempsearchlist_reload();
                    })


                }
                else
                {

                    let $target = $('.tempsearch-block-root');

                    // Clear pagination:
                    $target.find('.pagination-list').html('');
                    // Show messages depending on result set:
                    let $messageNoFilterMatchMessage = $target.find('.no-filter-match-message');
                    let $messageNoItemsMessage = $target.find('.no-items-present-message');

                    $messageNoFilterMatchMessage.addClass('d-none');
                    $messageNoItemsMessage.addClass('d-none');

                    let $hideIfNoFilterMatchElements = $target.find('.hide-if-no-filter-match');
                    let  $hideIfNoItemsElements = $target.find('.hide-if-no-content');

                    if(data.pagination.itemsTotal > 0)
                    {
                        $messageNoItemsMessage.addClass('d-none');
                        $hideIfNoItemsElements.removeClass('force-hide');

                        if(data.pagination.itemsFiltered < 1)
                        {
                            $messageNoFilterMatchMessage.removeClass('d-none');
                            $hideIfNoFilterMatchElements.addClass('force-hide');
                        }
                        else
                        {
                            $messageNoFilterMatchMessage.addClass('d-none');
                            $hideIfNoFilterMatchElements.removeClass('force-hide');
                        }
                    }
                    else
                    {
                        $hideIfNoItemsElements.addClass('force-hide');
                        $messageNoItemsMessage.removeClass('d-none');
                    }
                }
            }

        });

    });
}

$(function() {

    // console.log('INIT', $('.mixitup-container-tempsearch-list'));

    if($('.mixitup-container-tempsearch-list').length > 0)
    {

        // keep $(this) available:
        var _this = $(this);

        var mixMainNode = $('.mixitup-container-tempsearch-list');
        ds81_mix_init(mixMainNode, function (){

            // ds81_init_click_href_items();


            // Bind change listeners to result affecting filter inputs:
            _this.find('select[name="i_pagination_length"]').on('changed.bs.select', ds81_tempsearchlist_reload);
            // Add keyup listener to text search:
            _this.find('input[name="i_search_filter"]').typeWatch({
                callback: function (value) {
                    ds81_tempsearchlist_reload();
                },
                wait: 50,
                highlight: true,
                allowSubmit: false,
                captureLength: 1
            });

            // Initial load:
            ds81_tempsearchlist_reload();

        }, {
            data: {
                uidKey: 'uid',
                dirtyCheck: true
            },
            render: {
                target: ds81_tempsearchlist_render_dataset_tempsearch
            }
        });

    }

    $(window).on('rettdunt.fe.update.shifts', ds81_tempsearchlist_reload);

});

function ds81_init_shift_items()
{
    $('.mix-element.tempsearch-item').each(function () {
       if(!$(this).hasClass('click-init') && $(this).hasClass('status-open'))
       {
           $(this).addClass('click-init');
           $(this).on('click', function (e) {

               var _this = $(this);

               var $tplNode = $('.template_tempsearchlist_application').first();

               var diaglogTitleTemplateHtml = $tplNode.data('dialog-title');
               var diaglogTitleTemplate = _.template(diaglogTitleTemplateHtml);
               var diaglogTitleTemplateRendered = diaglogTitleTemplate({
                   data: $(e.currentTarget).data('all'),
                   uid: $(e.currentTarget).data('uid')
               });

               var tplHtml = $tplNode.html();
               var tplTitle = diaglogTitleTemplateRendered;
               var tpl = _.template(tplHtml);
               var tplRendered = tpl({
                   data: $(e.currentTarget).data('all'),
                   uid: $(e.currentTarget).data('uid')
               });

               BootstrapDialog.show({
                   title: tplTitle,
                   message: tplRendered,
                   type: BootstrapDialog.TYPE_PRIMARY,
                   size: BootstrapDialog.SIZE_WIDE,
                   closable: true,
                   nl2br: false,
                   data: {
                       buttonEvent: e
                   },
                   onhidden: function(dialogRef){
                       // Bugfix: Keep modal-open class in body tag of modals are present:
                       setTimeout(function () {
                           $.each(BootstrapDialog.dialogs, function(id, dialog){
                               $('body').addClass('modal-open');
                           });
                       }, 10)
                   },
                   onshow: function(dialogRef){

                       // Get body ref:
                       var $body = $(dialogRef.getModalBody());

                       // Link close button:
                       $body.find('.close-dialog').on('click', function (e) {
                           e.preventDefault();

                           // Do nothing if disabled:
                           if($(this).hasClass('disabled') || $(this).prop('disabled'))
                           {
                               return false;
                           }

                           // Close:
                           dialogRef.close();

                       }).css('cursor', 'pointer');

                       // Init hold to confirm:
                       ds81_init_hold_to_confirm_buttons($body);

                       $body.find('.status-wrapper .status-booking').removeClass('d-none').animate({
                           opacity: 0,
                           }, {
                               duration: 0
                           }
                       ).slideUp(0);
                       $body.find('.status-wrapper .status-success').removeClass('d-none').animate({
                               opacity: 0,
                           }, {
                               duration: 0
                           }
                       ).slideUp(0);
                       $body.find('.status-wrapper .status-failed').removeClass('d-none').animate({
                               opacity: 0,
                           }, {
                               duration: 0
                           }
                       ).slideUp(0);
                       $body.find('.status-wrapper .status-error').removeClass('d-none').animate({
                               opacity: 0,
                           }, {
                               duration: 0
                           }
                       ).slideUp(0);

                       $body.find('.btn-hold-to-confirm').on('holdConfirmed', function () {
                          $(this).animate({
                              opacity: 0,
                              height: 0
                          }, {
                              duration: 300,
                              easing: 'linear',
                              complete: function () {
                                  $(this).remove();
                              }
                          });

                           $(this).closest('.status-wrapper').find('.status-booking').animate({
                               opacity: 1,
                           }, {
                               duration: 300,
                               easing: 'linear',
                           }).slideDown(300);

                           var $_this = $(this);


                           $.ajax(
                               $(this).data('signon-endpoint'), // Service-URL
                               {
                                   type: 'post',
                                   dataType: 'json',
                                   success: function (data, textStatus, jqXHR) { // AJAX was ok

                                       if(typeof data !== "undefined")
                                       {
                                           if(typeof data.result !== "undefined")
                                           {

                                               switch (data.result)
                                               {
                                                   case('error_shift_covered'):
                                                       $body.find('.status-wrapper .status-booking').animate({
                                                           opacity: 0,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideUp(300);

                                                       $body.find('.status-wrapper .status-failed').animate({
                                                           opacity: 1,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideDown(300);
                                                       break;

                                                   case('ok'):
                                                       $body.find('.status-wrapper .status-booking').animate({
                                                           opacity: 0,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideUp(300);

                                                       $body.find('.status-wrapper .status-success').animate({
                                                           opacity: 1,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideDown(300);
                                                       break;

                                                   default:
                                                       $body.find('.status-wrapper .status-booking').animate({
                                                           opacity: 0,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideUp(300);

                                                       $body.find('.status-wrapper .status-error').animate({
                                                           opacity: 1,
                                                       }, {
                                                           duration: 300,
                                                           easing: 'linear',
                                                       }).slideDown(300);
                                                       break;
                                               }
                                           }
                                           else
                                           {
                                               $body.find('.status-wrapper .status-booking').animate({
                                                   opacity: 0,
                                               }, {
                                                   duration: 300,
                                                   easing: 'linear',
                                               }).slideUp(300);

                                               $body.find('.status-wrapper .status-error').animate({
                                                   opacity: 1,
                                               }, {
                                                   duration: 300,
                                                   easing: 'linear',
                                               }).slideDown(300);
                                           }
                                       }
                                       else
                                       {
                                           $body.find('.status-wrapper .status-booking').animate({
                                               opacity: 0,
                                           }, {
                                               duration: 300,
                                               easing: 'linear',
                                           }).slideUp(300);

                                           $body.find('.status-wrapper .status-error').animate({
                                               opacity: 1,
                                           }, {
                                               duration: 300,
                                               easing: 'linear',
                                           }).slideDown(300);
                                       }
                                   },
                                   error: function (jqXHR, textStatus, errorThrown) {

                                       $body.find('.status-wrapper .status-booking').animate({
                                           opacity: 0,
                                       }, {
                                           duration: 300,
                                           easing: 'linear',
                                       }).slideUp(300);

                                       $body.find('.status-wrapper .status-error').animate({
                                           opacity: 1,
                                       }, {
                                           duration: 300,
                                           easing: 'linear',
                                       }).slideDown(300);

                                   }

                               }
                           );


                       });



                   }
               });


           });
       }
    });
}
